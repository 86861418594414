// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';

const REACT_APP_SENTRY_ENV = process.env.REACT_APP_SENTRY_ENV;
const SENTRY_DSN =
	process.env.REACT_APP_SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;

if (REACT_APP_SENTRY_ENV === 'prod') {
	Sentry.init({
		dsn: SENTRY_DSN,
		/* beforeSend(event, hint) {
      // Check if it is an exception, and if so, show the report dialog
      if (event.exception) {
        Sentry.showReportDialog({ eventId: event.event_id }); // user feedback report (modal dialog)
      }
      return event;
    }, */
		// Adjust this value in production, or use tracesSampler for greater control
		tracesSampleRate: 1.0
		// ...
		// Note: if you want to override the automatic release value, do not set a
		// `release` value here - use the environment variable `SENTRY_RELEASE`, so
		// that it will also get attached to your source maps
	});
}
