export class PaymentMethod {
	constructor(data) {
		this._id = data._id;
		this.recurrent = data.recurrent || false;
		this.trial_period_days = data.trial_period_days || '';
		this.interval_count = data.interval_count || '';
		this.name = data.name || '';
		this.recurrent_interval = data.recurrent_interval || '';
		this.tariffs = data.tariffs || [
			{
				kinship: [''],
				sex: '',
				min_age: '',
				max_age: '',
				price: ''
			}
		];
		this.paymentAddress = data.paymentAddress || [{ city: '', address: '' }];
		this.paymentForms = data.paymentForms || [
			{ recharge: 0, name: '', value: '' }
		];
	}
}
