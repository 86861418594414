import { Map as map } from 'immutable';
import {
	SEARCHDASHBOARD,
	SEARCHPAGES,
	SEARCHROWSPERPAGE,
	TABPOSITION,
	USERLOGS,
	SEARCHUSERLOGS,
	UPDATEPAYMENTCUSTOMER,
	BACKTODASHBOARD,
	GETCAMPAINGS,
	GETCAMPAIGNINFO,
	VIEWREGISTRY,
	PENDINGDASHBOARD,
	SAVE_MEDICAL_CONSULTS,
	SAVE_CONSULT_BY_ID,
	VOLUNTEERS_BY_SPECIALTY,
	NEXT_AFFILIATION_DATA,
	SET_CURRENT_SEARCH_DATE,
	RENEWSUBSCRIPTIONS
} from '../actionTypes';

const now = new Date();
// const nextMonth = new Date(new Date().setMonth(now.getMonth() + 1));

const initialState = map({
	data: [],
	totalData: 0,
	marketingCampaigns: [],
	campaignInfo: {},
	registry: {},
	isSub: false,
	isSearch: false,
	page: 0,
	rowsPerPage: 5,
	valueTab: 0,
	isBack: false,
	pending: [],
	consults: [],
	viewConsult: {},
	volunteers: [],
	searchDates: [new Date(now.getFullYear(), now.getMonth(), 1), now],
	renewSubscriptions: []
});

function search(state = initialState, action) {
	switch (action.type) {
		case SEARCHDASHBOARD:
			return state
				.set('data', action.payload.values.data.docs)
				.set('isSearch', action.payload.values.isSearch)
				.set('totalData', action.payload.values.data.total)
				.set('rowsPerPage', 5)
				.set('page', 0);

		case NEXT_AFFILIATION_DATA:
			let dataAffiliation = state.get('data');
			if (action.payload.values.type === 'changeRow') {
				dataAffiliation = action.payload.values.data.docs;
				return state.set('data', dataAffiliation).set('page', 0);
			} else {
				dataAffiliation = [
					...dataAffiliation,
					...action.payload.values.data.docs
				];
				return state.set('data', dataAffiliation);
			}

		case SEARCHPAGES:
			return state.set('page', action.payload.values.page);

		case UPDATEPAYMENTCUSTOMER:
			let data = action.payload.values.data;
			data[action.payload.values.index].payment_id.status =
				action.payload.values.status;
			return state.set('data', data);

		case TABPOSITION:
			return state.set('valueTab', action.payload.values.valueTab);

		case SEARCHROWSPERPAGE:
			return state
				.set('rowsPerPage', action.payload.values.rowsPerPage)
				.set('page', action.payload.values.page);

		case USERLOGS:
			return state
				.set('userLogs', action.payload.values.data)
				.set('isSearchUser', action.payload.values.data)
				.set('page', 0)
				.set('rowsPerPage', 5);

		case SEARCHUSERLOGS:
			return state
				.set('userLogs', action.payload.values.data)
				.set('isSearchUser', action.payload.values.data)
				.set('page', 0)
				.set('rowsPerPage', 5);

		case BACKTODASHBOARD:
			return state.set('isBack', action.payload.values.isBack);

		case GETCAMPAINGS:
			return state
				.set('marketingCampaigns', action.payload.values.data)
				.set('isSub', false)
				.set('page', 0)
				.set('rowsPerPage', 5);

		case GETCAMPAIGNINFO:
			return state
				.set('campaignInfo', action.payload.values.data)
				.set('isSub', true);

		case PENDINGDASHBOARD:
			return state
				.set('pending', action.payload.values.data)
				.set('page', 0)
				.set('rowsPerPage', 5);

		case VIEWREGISTRY:
			return state.set('registry', action.payload.values.data);

		// MEDICAL DASHBOARD
		case SAVE_MEDICAL_CONSULTS:
			return state.set('consults', action.payload.values);

		case SAVE_CONSULT_BY_ID:
			return state.set('viewConsult', action.payload.values);

		case VOLUNTEERS_BY_SPECIALTY:
			return state.set('volunteers', action.payload.values);

		case SET_CURRENT_SEARCH_DATE:
			return state.set('searchDates', action.payload);

		case RENEWSUBSCRIPTIONS:
			return state
				.set('renewSubscriptions', action.payload.values.data)
				.set('rowsPerPage', 5)
				.set('page', 0);

		default:
			return state;
	}
}

export default search;
