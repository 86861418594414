import { Map as map } from 'immutable';
import {
	GET_PRODUCTS,
	GET_PAYMENT_METHODS,
	GET_ADDONS,
	GET_VALIDATORS,
	GET_PROMOTIONS,
	GET_ACTIVE_PROMOTIONS,
	GET_SURVEYS
} from '../actionTypes';

import { Product } from '../../src/models/product';
import { PaymentMethod } from '../../src/models/paymentMethod';
import { Validator } from '../../src/models/validator';
import { Addon } from '../../src/models/addon';
import { Promotion } from '../../src/models/promotion';

const initialState = map({
	listProducts: [],
	listPaymentMethods: [],
	listValidators: [],
	listAddons: [],
	listPromotions: [],
	listSurveys: [],
	activePromotions: [],
	product: new Product({}),
	paymentMethod: new PaymentMethod({}),
	validator: new Validator({}),
	addon: new Addon({}),
	promotion: new Promotion({}),
	page: 0,
	rowsPerPage: 5
});

const product = (state = initialState, action) => {
	switch (action.type) {
		// PRODUCT
		case GET_PRODUCTS:
			return state.set('listProducts', action.payload);
		// SURVEYS
		case GET_SURVEYS:
			return state.set('listSurveys', action.payload);
		// PAYMENT METHOD
		case GET_PAYMENT_METHODS:
			return state.set('listPaymentMethods', action.payload);
		// ADDON
		case GET_ADDONS:
			return state.set('listAddons', action.payload);
		// VALIDATOR
		case GET_VALIDATORS:
			return state.set('listValidators', action.payload);
		// PROMOTIONS
		case GET_PROMOTIONS:
			return state.set('listPromotions', action.payload);
		case GET_ACTIVE_PROMOTIONS:
			return state.set('activePromotions', action.payload);
		default:
			return state;
	}
};

export default product;
