import { Map as map } from 'immutable';
import {
	SAVE_VOLUNTARY_DATA,
	CLEAN_VOLUNTARY_DATA,
	SAVE_CONSULTATION_FEEDBACK,
	CLEAN_MEDICAL_CONSULT,
	GET_VOLUNTEERS
} from '../actionTypes';

import { Voluntary } from '../../src/models/voluntary';

const BaseVoluntary = {
	voluntaryID: '',
	first_name: '',
	last_name: '',
	document_type: 'default',
	doc_dni: null,
	dni: '',
	birth_date: null,
	email: '',
	cell_phone: '',
	country: 'default',
	city: '',
	specialty: 'default',
	msds: '',
	medical_college: '',
	doc_medical_title: null,
	video: null,
	accept_terms: false,
	policy_privacy: false,
	verify_number: '',
	agree_share_video: false
};

const initialState = map({
	listVolunteers: [],
	voluntary: new Voluntary({}),
	consultation: {
		attentionID: '',
		process_type: null,
		process_data: {
			diagnosis: '',
			suggested_medicines: '',
			indications: '',
			suggested_test: ''
		}
	}
});

const voluntary = (state = initialState, action) => {
	switch (action.type) {
		case GET_VOLUNTEERS:
			return state.set('listVolunteers', action.payload.data);
		case SAVE_VOLUNTARY_DATA:
			// let voluntary = action.payload.values;
			const voluntary = new Voluntary(action.payload.values);
			if (voluntary.dni) voluntary.dni = parseInt(voluntary.dni);
			return state.set('voluntary', voluntary);

		case CLEAN_VOLUNTARY_DATA:
			return state.set('voluntary', new Voluntary({}));

		case SAVE_CONSULTATION_FEEDBACK:
			return state.set('consultation', action.payload.values);

		case CLEAN_MEDICAL_CONSULT:
			return state.set('consultation', {
				attentionID: '',
				process_type: null,
				process_data: {
					diagnosis: '',
					suggested_medicines: '',
					indications: '',
					suggested_test: ''
				}
			});

		default:
			return state;
	}
};

export default voluntary;
