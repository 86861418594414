import { Map as map } from 'immutable';
import { SET_AWARDS, SET_AWARD } from '../actionTypes';

import { Award } from '../../src/models/award';

const initialState = map({
	listAwards: [],
	award: new Award({}),
	page: 0,
	rowsPerPage: 5
});

const awards = (state = initialState, action) => {
	switch (action.type) {
		case SET_AWARDS:
			return state.set('listAwards', action.payload);
		case SET_AWARD:
			return state.set('award', action.payload);
		default:
			return state;
	}
};

export default awards;
