export class Promotion {
	constructor(data) {
		this._id = data._id || '';
		this.name = data.name || '';
		this.promotion_code = data.promotion_code || '';
		this.active = data.active || true;

		this.configuration = data.configuration || [
			{
				description: '',
				background_color: '',
				position: ''
			}
		];

		this.start_date = data.start_date || null;
		this.end_date = data.end_date || null;
		this.quantity = data.quantity || 0;
		this.min_beneficiaries = data.min_beneficiaries || 1;
		this.max_beneficiaries = data.max_beneficiaries || 1;

		this.is_group_discount = data.is_group_discount || false;
		this.group_discount = data.group_discount || 0;

		this.is_family_discount = data.is_family_discount || false;
		this.family_config = data.family_config || [];

		this.is_individual_discount = data.is_individual_discount || false;
		this.individual_config = data.individual_config || [];
	}
}
