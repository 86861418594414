import { Map as map } from 'immutable';
import {
	SAVE_TDC_PAYMENTS,
	SAVE_OVERDUE_PAYMENTS,
	SAVE_PENDING_PAYMENTS
} from '../actionTypes';

const initialState = map({
	tdcPayments: [],
	overduePayments: [],
	pendingPayments: []
});

const payments = (state = initialState, action) => {
	switch (action.type) {
		case SAVE_TDC_PAYMENTS:
			return state.set('tdcPayments', action.payload.values.data);
		case SAVE_OVERDUE_PAYMENTS:
			return state.set('overduePayments', action.payload.values.data);
		case SAVE_PENDING_PAYMENTS:
			return state.set('pendingPayments', action.payload.values.data);
		default:
			return state;
	}
};

export default payments;
