import { combineReducers } from 'redux';

import login from './login';
import search from './search';
import customers from './customers';
import affiliation from './affiliation';
import profile from './profile';
import sos19 from './sos19';
import leads from './leads';
import voluntary from './voluntary';
import marketing from './marketing';
import users from './users';
import product from './product';
import promoter from './promoter';
import commission from './commission';
import points from './points';
import conditions from './condition';
import awards from './awards';
import bigbroder from './bigbroder';
import payments from './payments';
import country from './country';
import projection from './projection';
import verification from './verification';
import membership from './membership';
import ally from './ally';
import currencies from './currencies';
import signupData from './signupData';

const rootReducer = combineReducers({
	login,
	search,
	customers,
	affiliation,
	profile,
	sos19,
	leads,
	voluntary,
	marketing,
	users,
	product,
	promoter,
	commission,
	points,
	conditions,
	awards,
	payments,
	bigbroder,
	country,
	projection,
	verification,
	membership,
	ally,
	currencies,
	signupData
});

export default rootReducer;
