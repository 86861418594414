const BaseBeneficiary = (props) => {
	return {
		first_name: props.first_name || '',
		last_name: props.last_name || '',
		middle_name: props.middle_name || '',
		nationality: props.nationality || 'default',
		dni: props.dni || '',
		second_nationality: props.second_nationality || 'default',
		second_dni: props.second_dni || '',
		sex: props.sex || 'default',
		civil_status: props.civil_status || 'default',
		email: props.email || '',
		phone_one: props.phone_one || '',
		country: props.country || 'default',
		state: props.state || 'default',
		municipality: props.municipality || 'default',
		city: props.city || 'default',
		id_state: props.id_state || null,
		id_municipality: props.id_municipality || null,
		id_city: props.id_city || null,
		province: props.province || 'default',
		barangay: props.barangay || 'default',
		district: props.district || 'default',
		address: props.address || '',
		birth_date: props.birth_date || null,
		age: props.age || null,
		kinship: props.kinship || 'default',
		other_kinship: props.other_kinship || '',
		insurance_company: props.insurance_company || '',
		medicines: props.medicines || false,
		medicines_price: props.medicines_price || 0,
		requireMedicalVerification: props.requireMedicalVerification,
		requireSupportVerification: props.requireSupportVerification,
		NotEditCustomer: props.NotEditCustomer,
		medicalVerification: props.medicalVerification,
		funeral_service: props.funeral_service || false,
		funeral_service_price: props.funeral_service_price || 0,
		funeral_service_coverage: props.funeral_service_coverage || 0,
		id_user_creator: props.id_user_creator,
		products: props.products || [],
		product_id: props.product_id || '',
		product_type: props.product_type || {},
		product_price: props.product_price || 0,
		questions: props.questions || [],
		answers: props.answers || [],
		pending_validation: '',
		conditions_pregnant: false,
		coupon: props.coupon,
		formErrors: props.formErrors || [],
		show_warning: props.show_warning || false,
		complete_surveys: props.complete_surveys || false,
		beneficiary_sha: props.beneficiary_sha || '',
		total: props.total || 0,
		addons: props.addons || [],
		addons_id: props.addons_id || [],
		phil_health: props.phil_health ?? true,
		phil_health_number: props.phil_health_number || ''
	};
};

export default BaseBeneficiary;
