export class Condition {
	constructor(data) {
		this._id = data._id;
		this.name = data.name;
		this.description = data.description;
		this.self = data.self;
		this.my_allies = data.my_allies;
		this.customers = data.customers || [];
		this.payments = data.payments || [];
		this.users = data.users || [];
		this.points = data.points;
		this.status = data.status;
	}
}
