export default {
	first_name: '',
	last_name: '',
	sex: 'default',
	email: '',
	phone_one: '',
	birth_date: null,
	age: null,
	country: 'default',
	country_live: 'default',
	state: 'default',
	municipality: 'default',
	city: 'default',
	agree_policy_privacy: false,
	formErrors: {},
	// for create account
	password: '',
	password_confirm: ''
};
