import { Map as map } from 'immutable';
import { SET_ALL_CURRENCIES, SET_CURRENCY } from '../actionTypes';

const isServer = typeof window === 'undefined';
let initialState = map({});

if (!isServer) {
	initialState = map({
		// CURRENCY
		currencies: JSON.parse(localStorage.getItem('currencies')) || [],
		currency: localStorage.getItem('currency'),
		isoCode: localStorage.getItem('isoCode'),
		symbol: localStorage.getItem('symbol'),
		active_payment_forms: JSON.parse(
			localStorage.getItem('active_payment_forms')
		)
	});
}

function currencies(state = initialState, action) {
	switch (action.type) {
		case SET_CURRENCY:
			localStorage.setItem(
				'currency',
				JSON.stringify(action.payload.values?.currency)
			);
			localStorage.setItem('isoCode', action.payload.values?.currency?.isoCode);
			localStorage.setItem('symbol', action.payload.values?.currency?.symbol);
			localStorage.setItem(
				'active_payment_forms',
				JSON.stringify(action.payload.values?.currency?.active_payment_forms)
			);
			return state
				.set('currency', action.payload.values?.currency)
				.set('isoCode', action.payload.values?.currency?.isoCode)
				.set('symbol', action.payload.values?.currency?.symbol)
				.set(
					'active_payment_forms',
					action.payload.values?.currency?.active_payment_forms
				);

		case SET_ALL_CURRENCIES:
			localStorage.setItem('currencies', JSON.stringify(action.payload.values));
			return state.set('currencies', action.payload.values);

		default:
			return state;
	}
}

export default currencies;
