import { Map as map } from 'immutable';
import {
	SET_LIST_PARAMETERS,
	SET_CONDITIONS,
	SET_CONDITION
} from '../actionTypes';

import { Condition } from '../../src/models/condition';

const initialState = map({
	listConditions: [],
	condition: new Condition({}),
	comparisonParameters: [],
	paymentsParameters: [],
	customersParameters: [],
	usersParameters: [],
	page: 0,
	rowsPerPage: 5
});

const conditions = (state = initialState, action) => {
	switch (action.type) {
		case SET_CONDITIONS:
			return state.set('listConditions', action.payload);
		case SET_CONDITION:
			return state.set('condition', action.payload);
		case SET_LIST_PARAMETERS:
			return state.set(action.payload.type, action.payload.value);
		default:
			return state;
	}
};

export default conditions;
