import { Map as map } from 'immutable';
import { SET_ORIGIN } from '../actionTypes';

const isServer = typeof window === 'undefined';
let initialState = map({});
if (!isServer) {
	initialState = map({
		origin: localStorage.getItem('origin') || 'join'
	});
}

const membership = (state = initialState, action) => {
	switch (action.type) {
		case SET_ORIGIN:
			localStorage.setItem('origin', action.payload.values);
			return state.set('origin', action.payload.values);

		default:
			return state;
	}
};

export default membership;
