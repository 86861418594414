import { Map as map } from 'immutable';

import { SET_SIGNUP_DATA } from '../actionTypes';

const initialState = map({
	signupData: {}
});

const signupData = (state = initialState, action) => {
	switch (action.type) {
		case SET_SIGNUP_DATA:
			return state.set('signupData', action.payload);
		default:
			return state;
	}
};

export default signupData;
