import { Map as map } from 'immutable';
import {
	SAVE_GENERAL_DATA,
	SAVE_MOTIVES,
	SAVE_MEDICAL_QUESTIONS,
	MEDICAL_RESULT,
	CLEAN_DATA,
	SAVE_COVID_DATA,
	CLEAN_COVID_DATA
} from '../actionTypes';

const initialState = map({
	generalReference: {
		motive_id: 'default',
		symptom_start_date: null,
		medical_history: [
			{
				condition: 'Hipertensión arterial',
				value: false,
				message: '',
				formErrors: []
			},
			{
				condition: 'Diabetes Mellitus',
				value: false,
				message: '',
				formErrors: []
			},
			{
				condition: 'Asma',
				value: false,
				message: '',
				formErrors: []
			},
			{
				condition: 'EPOC',
				value: false,
				message: '',
				formErrors: []
			},
			{
				condition: 'Insuficiencia renal',
				value: false,
				message: '',
				formErrors: []
			},
			{
				condition: 'Problemas cardíacos',
				value: false,
				message: '',
				formErrors: []
			},
			{
				condition: 'Otros',
				value: false,
				message: '',
				formErrors: []
			},
			{
				condition: 'Cirugías y/o traumatismos previos',
				value: false,
				message: '',
				formErrors: []
			},
			{
				condition: 'Alergias',
				value: false,
				message: '',
				formErrors: []
			},
			{
				condition: 'Medicamentos que usa regularmente',
				value: false,
				message: '',
				formErrors: []
			},
			{
				condition: 'Ninguno',
				value: false,
				message: '',
				formErrors: []
			}
		],
		other_motive: '',
		main_questions: [],
		secundary_questions: [],
		description: '',
		exam_one: null,
		exam_two: null,
		first_name: '',
		last_name: '',
		dni: '',
		birth_date: null,
		sex: 'default',
		email: '',
		cell_phone: '',
		country: 'default',
		city: '',
		agree_policy_privacy: true,
		send_email: true,
		verify_number: '',
		accept_contact: false
	},
	motives: [],
	// Response
	alert: null,
	consultationId: '',
	tips_case_alert: [],
	general_tips: [],
	max_days: null,

	covidReferenceData: {
		age: 'default',
		sex: 'default',
		country: 'default',
		city: '',
		email: '',
		full_name: '',
		cell_phone: '',
		dni: '',
		symptomsVirus: [],
		othersSymptomsVirus: [],
		cormobility: [],
		contact_infected: '',
		works_in_health: false,
		traveled: null,
		ranks: [],
		formErrors: [],
		messageAlert: false,
		// RESULT
		message: '',
		steps: [],
		health_risk: '',
		risk_of_infection: '',
		steps_risk_infection: [],
		severe_health_risk_title: '',
		personId: '',
		successContact: false,
		agree_policy_privacy: true
	}
});

const sos19 = (state = initialState, action) => {
	switch (action.type) {
		case SAVE_GENERAL_DATA:
			return state.set('generalReference', action.payload.values);

		case SAVE_MOTIVES:
			return state.set('motives', action.payload.values);

		case SAVE_MEDICAL_QUESTIONS:
			let generalReference = state.get('generalReference');

			generalReference.main_questions = action.payload.values.main_questions;
			generalReference.secundary_questions =
				action.payload.values.secundary_questions;

			return state.set('generalReference', generalReference);

		case MEDICAL_RESULT:
			return state
				.set('alert', action.payload.values.alert)
				.set('consultationId', action.payload.values.consultationId)
				.set('tips_case_alert', action.payload.values.tips_case_alert)
				.set('general_tips', action.payload.values.general_tips)
				.set('max_days', action.payload.values.max_days);

		case CLEAN_DATA:
			generalReference = state.get('generalReference');
			generalReference = {
				motive_id: 'default',
				symptom_start_date: null,
				medical_history: [
					{
						condition: 'Hipertensión arterial',
						value: false,
						message: '',
						formErrors: []
					},
					{
						condition: 'Diabetes Mellitus',
						value: false,
						message: '',
						formErrors: []
					},
					{
						condition: 'Asma',
						value: false,
						message: '',
						formErrors: []
					},
					{
						condition: 'EPOC',
						value: false,
						message: '',
						formErrors: []
					},
					{
						condition: 'Insuficiencia renal',
						value: false,
						message: '',
						formErrors: []
					},
					{
						condition: 'Problemas cardíacos',
						value: false,
						message: '',
						formErrors: []
					},
					{
						condition: 'Otros',
						value: false,
						message: '',
						formErrors: []
					},
					{
						condition: 'Cirugías y/o traumatismos previos',
						value: false,
						message: '',
						formErrors: []
					},
					{
						condition: 'Alergias',
						value: false,
						message: '',
						formErrors: []
					},
					{
						condition: 'Medicamentos que usa regularmente',
						value: false,
						message: '',
						formErrors: []
					},
					{
						condition: 'Ninguno',
						value: false,
						message: '',
						formErrors: []
					}
				],
				main_questions: [],
				secundary_questions: [],
				description: '',
				exam_one: null,
				exam_two: null,
				first_name: '',
				last_name: '',
				dni: '',
				birth_date: null,
				sex: 'default',
				email: '',
				cell_phone: '',
				country: 'default',
				city: '',
				agree_policy_privacy: true,
				send_email: true,
				verify_number: '',
				accept_contact: false
			};

			return state
				.set('motives', [])
				.set('alert', null)
				.set('consultationId', '')
				.set('tips_case_alert', [])
				.set('general_tips', [])
				.set('max_days', null)
				.set('generalReference', generalReference);

		case SAVE_COVID_DATA:
			return state.set('covidReferenceData', action.payload.values);

		case CLEAN_COVID_DATA:
			let covidReferenceData = state.get('covidReferenceData');
			covidReferenceData = {
				age: 'default',
				sex: 'default',
				country: 'default',
				city: '',
				email: '',
				full_name: '',
				cell_phone: '',
				dni: '',
				symptomsVirus: [],
				othersSymptomsVirus: [],
				cormobility: [],
				contact_infected: '',
				works_in_health: false,
				traveled: null,
				ranks: [],
				formErrors: [],
				messageAlert: false,
				// RESULT
				message: '',
				steps: [],
				health_risk: '',
				risk_of_infection: '',
				steps_risk_infection: [],
				severe_health_risk_title: '',
				personId: '',
				successContact: false,
				agree_policy_privacy: true
			};
			return state.set('covidReferenceData', covidReferenceData);

		default:
			return state;
	}
};

export default sos19;
