import { Map as map } from 'immutable';
import { SET_PROMOTER, CLEAN_PROMOTER, GET_PROMOTERS } from '../actionTypes';

import { Promoter } from '../../src/models/promoter';

const initialState = map({
	listPromoters: [],
	promoter: new Promoter({}),
	page: 0,
	rowsPerPage: 5
});

const promoter = (state = initialState, action) => {
	switch (action.type) {
		case GET_PROMOTERS:
			return state.set('listPromoters', action.payload);
		case SET_PROMOTER:
			return state.set('promoter', new Promoter(action.payload));
		case CLEAN_PROMOTER:
			return state.set('promoter', new Promoter({}));
		default:
			return state;
	}
};

export default promoter;
