import { Map as map } from 'immutable';
import { SAVE_ALLY_SHARED_LINK } from '../actionTypes';
import Cookies from 'js-cookie';

const isServer = typeof window === 'undefined';
const cookie = Cookies.getJSON('allybound');
let initialState = map({});

if (!isServer) {
	initialState = map({
		share_link: cookie ? cookie.share_link : localStorage.getItem('share_link'),
		share_link_discount: cookie
			? cookie.share_link_discount
			: localStorage.getItem('share_link_discount')
	});
}

const ally = (state = initialState, action) => {
	switch (action.type) {
		case SAVE_ALLY_SHARED_LINK:
			localStorage.setItem('share_link', action.payload.share_link);
			localStorage.setItem(
				'share_link_discount',
				action.payload.share_link_discount
			);
			return state
				.set('share_link', action.payload.share_link)
				.set('share_link_discount', action.payload.share_link_discount);
		default:
			return state;
	}
};

export default ally;
