import { Map as map } from 'immutable';
import { GET_VERIFICATIONS, SET_VERIFICATION } from '../actionTypes';

const initialState = map({
	listVerifications: [],
	verification: {}
});

const verification = (state = initialState, action) => {
	switch (action.type) {
		case GET_VERIFICATIONS:
			return state.set('listVerifications', action.payload);
		case SET_VERIFICATION:
			return state.set('verification', action.payload);

		default:
			return state;
	}
};

export default verification;
